import { Grid, TextInput, Flex } from "@mantine/core";

const AidCard = ({ item, renderButtons, classes, travelData, formatValue }) => {
  return (
    <Grid align="stretch">
      <Grid.Col xs={12} md={6}>
        <Grid>
          <Grid.Col span={12}>
            <TextInput
              label="Nombre del titular"
              classNames={{
                label: classes.labelSecondary,
                input: classes.input,
              }}
              disabled={true}
              value={`${
                item.beneficiarios.find((p) => p.tipo === "TITULAR")?.nombres
              } ${
                item.beneficiarios.find((p) => p.tipo === "TITULAR")?.apellidos
              }`}
            />
          </Grid.Col>
          <Grid.Col span={12}>
            <TextInput
              label="Nombre del beneficiario"
              classNames={{
                label: classes.labelSecondary,
                input: classes.input,
              }}
              disabled={true}
              value={`${
                item.beneficiarios.find((p) => p.tipo === "BENEFICIARIO")
                  ?.nombres
              } ${
                item.beneficiarios.find((p) => p.tipo === "BENEFICIARIO")
                  ?.apellidos
              }`}
            />
          </Grid.Col>
          <Grid.Col span={12}>
            <TextInput
              label="Objetivo del viaje"
              classNames={{
                label: classes.labelSecondary,
                input: classes.input,
              }}
              disabled={true}
              value={item?.descripcion}
            />
          </Grid.Col>
        </Grid>
      </Grid.Col>

      <Grid.Col xs={12} md={6}>
        <Grid>
          {travelData(item).map((travel, index) => (
            <Grid.Col key={index} c="#004236" xs={12} md={6}>
              <TextInput
                label={travel.label}
                value={formatValue(travel.value)}
                classNames={{
                  label: classes.labelSecondary,
                  input: classes.input,
                }}
                disabled={true}
              />
            </Grid.Col>
          ))}
        </Grid>
      </Grid.Col>

      <Grid.Col span={12}>
        <Flex align="center" gap={8} justify="flex-start" wrap="wrap">
          {renderButtons(item)}
        </Flex>
      </Grid.Col>
    </Grid>
  );
};

export default AidCard;
