import {
  GlobalCard,
  useStyles,
  useWindowSize,
} from "@mfe/js-common-ave-uiutils";
import { Box, Flex, Text, Button, LoadingOverlay } from "@mantine/core";
import { useForm } from "@mantine/form";

import {
  useIsAuthenticated,
  MsalProvider,
  useMsal,
} from "@mfe/react-common-ave-msal";

import {
  es,
  getAccessTokenApi,
  fetchAllCitiesData,
  fetchTravelHistoryData,
  fetchTravelHistoryFilterData,
  parseDateWihtFormat,
  filterEmpty,
  fetchTravelTypesListData,
  parseDateLocaleFormat,
  formatter,
} from "@mfe/ts-common-ave-utilitaries";
import { useEffect, useState } from "react";
import { navigateToUrl } from "single-spa";
import FormFilter from "../components/formFilter.component";
import Results from "../components/results.component";

export default function Historical({ organization, msalInstance, isAux }) {
  return (
    <MsalProvider instance={msalInstance}>
      <HistoricalComponent organization={organization} isAux={isAux} />
    </MsalProvider>
  );
}

const travelData = [
  { value: "345678", label: "No de referencia" },
  { value: 456789, label: "Valor total" },
  { value: new Date(), label: "Fecha inicial" },
  { value: new Date(), label: "Fecha final" },
  { value: "Cali", label: "Ciudad origen" },
  { value: "Barranquilla", label: "Ciudad destino" },
];

export const HistoricalComponent = ({ organization, isAux }) => {
  const isAuthenticated = useIsAuthenticated();
  const { classes } = useStyles();
  const [showDetails, setShowDetails] = useState(false);
  const { instance, accounts } = useMsal();
  const [tokenApi, setTokenApi] = useState("");
  const userData = JSON.parse(localStorage.getItem("userData"));
  const defaultFilters =
    JSON.parse(localStorage.getItem("historicalFilters")) || null;
  const [pageNumber, setPageNumber] = useState(1);
  const [hasSearched, setHasSearched] = useState(false);
  const [defaultSearching, setDefaultSearching] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    allCities: [],
    travelHistory: [],
    travelTypesList: [],
  });

  const historicalList = [
    {
      id: [
        "GUARDADO",
        "PRAPROBADO",
        "PENDAPROBO",
        "PEDNCONFIRMACION",
        "PEDNCONFIRMACIONLEG",
        "CONFIRMADO",
        "RECHAZADO",
        "APROCUPCONFIRM",
        "APROPAGSAP",
        "CONFIRPAGSAP",
        "APROERRSAP",
        "APROBADO",
        "APROSINPAGSAP",
      ],
      description: "Anticipo",
      buttons: [
        { label: "Ver detalle", action: (item) => handleViewDetails(item) },
      ],
    },
    {
      id: [
        "ENPROLEGAL",
        "CONFSINPAGSAP",
        "LEGALIZENV",
        "LEGALIZREC",
        "LEGAPROSAF",
        "LEGAPROSEC",
        "LEGAPROSNV",
        "LEGALIZENVPREV",
      ],
      description: "Legalización",
      buttons: [
        {
          label: "Ver detalle anticipo",
          action: (item) => handleViewDetails(item),
        },
        {
          label: "Ver detalle legalización",
          action: (item) => handleLegalizationDetails(item),
        },
      ],
    },
    {
      id: ["VIJCERRADO", "CERRADO", "CERRERRSAP"],
      description: "Cerrado",
      buttons: [
        {
          label: "Ver detalle anticipo",
          action: (item) => handleViewDetails(item),
        },
        {
          label: "Ver detalle legalización",
          action: (item) => handleLegalizationDetails(item),
        },
      ],
    },
    {
      id: ["ANULFAVORECP", "ANULSINVIAJE"],
      description: "Anulado",
      buttons: [
        {
          label: "Ver detalle anticipo",
          action: (item) => handleViewDetails(item),
        },
        {
          label: "Ver detalle legalización",
          action: (item) => handleLegalizationDetails(item),
        },
      ],
    },
  ];

  const isLegalizationApproved = (travel) => {
    const listStateApproved = [
      "LEGAPROSAF",
      "LEGAPROSEC",
      "LEGAPROSNV",
      "ANULFAVORECP",
    ];

    return Boolean(
      travel?.listaEstados.filter((state) =>
        listStateApproved.includes(state.paramFkEstadoViaje)
      )[0]
    );
  };

  const handleViewDetails = (requestItem) => {
    localStorage.setItem("liquidationID", requestItem.id);
    localStorage.setItem("isHistorical", "true");
    navigateToUrl("/reserva-detalles");
  };

  const handleLegalizationDetails = (requestItem) => {
    localStorage.setItem("liquidationID", requestItem.id);
    localStorage.setItem("isHistorical", "true");
    navigateToUrl("/detalle-legalizacion");
  };

  const isMd = useWindowSize("md");

  const form = useForm({
    initialValues: {
      status: "",
      dates: [null, null],
      destinationCity: "",
      idAid: "",
      idUser: "",
    },
  });

  useEffect(() => {
    getAccessTokenApi(instance, accounts, setTokenApi);
  }, []);

  useEffect(() => {
    if (tokenApi) {
      fetchAllCitiesData(setData, tokenApi, setLoading);
      fetchTravelTypesListData(setData, tokenApi, setLoading);
      if (!defaultFilters) {
        !isAux &&
          form.setFieldValue("idUser", userData?.datosUsuario?.codigoEmpleado);
      }
    }
  }, [tokenApi]);

  useEffect(() => {
    if (data?.allCities?.length > 0 && defaultFilters && !isAux) {
      form.setValues({
        ...defaultFilters,
        dates: defaultFilters?.dates?.includes(null)
          ? [null, null]
          : [
              new Date(defaultFilters.dates[0]),
              new Date(defaultFilters.dates[1]),
            ],
      });
      setDefaultSearching(true);
    }
  }, [data?.allCities.length]);

  useEffect(() => {
    if (defaultSearching) {
      handleSubmit(form.values);
    }
  }, [defaultSearching]);

  const hasOnlyUserId = () => {
    const list = Object.keys(form.values);
    let flagOthers = false;
    let flag = true;

    list.forEach((key) => {
      if (key !== "idUser") {
        if (
          typeof form.values[key] == "string" &&
          form.values[key].length > 0
        ) {
          flagOthers = true;
        } else if (
          Array.isArray(form.values[key]) &&
          !form.values[key].includes(null)
        ) {
          flagOthers = true;
        }
      } else {
        if (form.values[key].length > 0 && !flagOthers) {
          flag = true;
        } else {
          flag = false;
        }
      }
    });

    return flag;
  };

  function renderButtonsByState(travel) {
    const { fkEstadoViaje } = travel;
    const stateInfo = historicalList.find((item) =>
      item.id.includes(fkEstadoViaje)
    );

    if (stateInfo) {
      const buttons = stateInfo.buttons.map((button) => {
        if (
          button.label === "Ver detalle legalización" &&
          !isLegalizationApproved(travel)
        ) {
          return null;
        }
        return (
          <Button
            key={button.label}
            onClick={() => button.action({ id: travel.id })}
            className={classes.button}
            fullWidth
          >
            {button.label}
          </Button>
        );
      });
      return buttons.filter(Boolean);
    }
    return null;
  }

  const payload = {
    EstadosViaje:
      form.values.status.split("/")[0].length > 0
        ? form.values.status.split("/")
        : [],
    FechaInicio: form.values.dates[0]
      ? parseDateWihtFormat(form.values.dates[0], "YYYY-MM-DDTHH:mm:ss.SSSZ")
      : "",
    FechaFin: form.values.dates[1]
      ? parseDateWihtFormat(form.values.dates[1], "YYYY-MM-DDTHH:mm:ss.SSSZ")
      : "",
    CiudadDestino:
      form.values.destinationCity.length > 0
        ? form.values.destinationCity.split("/")[0]
        : "",
    IdViaje: form.values?.idAid?.length > 0 ? form.values.idAid : 0,
  };

  const handleSubmit = (values) => {
    setHasSearched(true);
    setPageNumber(1);
    setData((prev) => ({
      ...prev,
      travelHistory: [],
    }));
    hasOnlyUserId()
      ? fetchTravelHistoryData(
          setData,
          setLoading,
          tokenApi,
          values.idUser,
          isAux ? true : false,
          pageNumber,
          10
        )
      : fetchTravelHistoryFilterData(
          setData,
          setLoading,
          tokenApi,
          values.idUser,
          isAux ? true : false,
          pageNumber,
          10,
          filterEmpty(payload)
        );
    localStorage.setItem("historicalFilters", JSON.stringify(values));
  };

  const resetFilter = () => {
    form.setValues((prev) => ({
      status: "",
      dates: [null, null],
      destinationCity: "",
      idAid: "",
      idUser: isAux ? userData?.datosUsuario?.codigoEmpleado : "",
    }));
    setHasSearched(false);
    setPageNumber(1);
    setData((prev) => ({
      ...prev,
      travelHistory: [],
    }));
    localStorage.removeItem("historicalFilters");
  };

  const travelData = (item) => [
    {
      value: item?.listaDestinos?.[0]?.auxilioMedico?.NumeroRef,
      label: "No de referencia",
    },
    {
      value: item?.anticipoViaje?.[0]?.totalConServiciosCop,
      label: "Valor total",
    },
    {
      value: new Date(item?.listaDestinos?.[0]?.fechaIda),
      label: "Fecha inicial",
    },
    {
      value: new Date(item?.listaDestinos?.[0]?.fechaSalida),
      label: "Fecha final",
    },
    {
      value: item?.listaDestinos?.[0]?.nombreCiudadOrigen,
      label: "Ciudad origen",
    },
    {
      value: item?.listaDestinos?.[0]?.nombreCiudadDestino,
      label: "Ciudad destino",
    },
  ];

  const formatValue = (value) => {
    if (value instanceof Date) {
      return parseDateLocaleFormat(value);
    } else if (typeof value === "number") {
      return formatter.format(value);
    } else {
      return value;
    }
  };

  const findTravelName = (alias) => {
    const travel = data.travelTypesList.find((item) => item.alias === alias);
    return travel?.nombre;
  };

  useEffect(() => {
    if (tokenApi && pageNumber !== 1) {
      hasOnlyUserId()
        ? fetchTravelHistoryData(
            setData,
            setLoading,
            tokenApi,
            form.values.idUser,
            isAux ? true : false,
            pageNumber,
            10
          )
        : fetchTravelHistoryFilterData(
            setData,
            setLoading,
            tokenApi,
            form.values.idUser,
            isAux ? true : false,
            pageNumber,
            10,
            filterEmpty(payload)
          );
    }
  }, [tokenApi, pageNumber]);

  const formFilterProps = {
    GlobalCard,
    form,
    handleSubmit,
    historicalList,
    classes,
    utils: { es },
    data,
    isAux,
    hasSearched,
    resetFilter,
  };

  const resultProps = {
    data,
    GlobalCard,
    isMd,
    showDetails,
    setShowDetails,
    isAux,
    classes,
    pageNumber,
    renderButtonsByState,
    findTravelName,
    utils: { parseDateLocaleFormat, formatter },
    formatValue,
    travelData,
    setPageNumber,
  };
  if (loading) {
    return (
      <LoadingOverlay
        visible={loading}
        overlayBlur={50}
        loaderProps={{ color: "#cbd300" }}
      />
    );
  }

  return isAuthenticated ? (
    <Box maw={{ xs: "100%", md: "80%" }} mx="auto" c="#004236" pb="3rem">
      <Text fw={700} size={24} component="h1" color="#004236">
        Histórico de {isAux ? "auxilios" : "viajes"}
      </Text>

      <FormFilter {...formFilterProps} />

      {data?.travelHistory?.length === 0 && hasSearched && (
        <GlobalCard>
          <Flex
            gap={12}
            justify="center"
            align="center"
            direction="column"
            sx={{ height: isMd ? "auto" : "224px" }}
          >
            <Text fw={700} color="#004236" ta="center">
              No se encontraron históricos.
            </Text>
          </Flex>
        </GlobalCard>
      )}
      <Results {...resultProps} />
    </Box>
  ) : null;
};
