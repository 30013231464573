import { Text, Flex, Badge, Button, Center } from "@mantine/core";
import { BiSolidPlusCircle, BiSolidMinusCircle } from "react-icons/bi";
import AidCard from "./aidCard.component";
import TravelCard from "./travelCard.component";

const Results = ({
  data,
  GlobalCard,
  isMd,
  showDetails,
  setShowDetails,
  isAux,
  classes,
  pageNumber,
  renderButtonsByState,
  findTravelName,
  utils,
  formatValue,
  travelData,
  setPageNumber,
}) => {
  return (
    <>
      {data?.travelHistory?.length > 0 && (
        <>
          {data?.travelHistory?.map((item, index) => (
            <GlobalCard
              styles={{
                position: "relative",
                marginBottom: "36px",
              }}
              key={index}
            >
              <Flex
                justify="center"
                align="center"
                gap="4px"
                sx={(theme) => ({
                  [theme.fn.smallerThan("md")]: {
                    paddingTop: "20px",
                    flexDirection: "column",
                  },
                })}
                mb={12}
              >
                <Flex align="center" gap={4}>
                  <Text color="#004236" fw={700} fs="md">
                    {`${isAux ? "Número de auxilio" : "ID Viaje"}: ${item?.id}`}
                  </Text>
                  {isMd &&
                    (showDetails ? (
                      <BiSolidMinusCircle
                        size="1.25rem"
                        style={{
                          flexShrink: 0,
                          color: "#cbd300",
                        }}
                        onClick={() => setShowDetails(false)}
                        data-testid="hide-details-button"
                      />
                    ) : (
                      <BiSolidPlusCircle
                        size="1.25rem"
                        style={{
                          flexShrink: 0,
                          color: "#cbd300",
                        }}
                        onClick={() => setShowDetails(true)}
                        data-testid="show-details-button"
                      />
                    ))}
                </Flex>
                <Badge
                  sx={{
                    backgroundColor: "#cbd300",
                    color: "#004236",
                  }}
                >
                  {
                    item?.listaEstados?.find(
                      (status) =>
                        status.paramFkEstadoViaje === item?.fkEstadoViaje
                    )?.paramNombreEstado
                  }
                </Badge>
              </Flex>
              {(isMd ? showDetails : true) &&
                (!isAux ? (
                  <TravelCard
                    item={item}
                    renderButtons={renderButtonsByState}
                    findTravelName={findTravelName}
                    classes={classes}
                    utils={utils}
                  />
                ) : (
                  <AidCard
                    item={item}
                    renderButtons={renderButtonsByState}
                    classes={classes}
                    formatValue={formatValue}
                    travelData={travelData}
                  />
                ))}
            </GlobalCard>
          ))}
          <Center mt={12}>
            <Button
              className={classes.button}
              onClick={() => setPageNumber(pageNumber + 1)}
            >
              Cargar más
            </Button>
          </Center>
        </>
      )}
    </>
  );
};

export default Results;
