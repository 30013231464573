import { Grid, TextInput, Flex } from "@mantine/core";

import React from "react";

const TravelCard = ({
  item,
  renderButtons,
  findTravelName,
  utils,
  classes,
}) => {
  const renderCity = (city, label) => {
    return (
      <Grid.Col span={6}>
        <TextInput
          label={label}
          value={city}
          classNames={{
            label: classes.labelSecondary,
            input: classes.input,
          }}
          disabled={true}
        />
      </Grid.Col>
    );
  };
  return (
    <Grid align="center">
      <Grid.Col span={6}>
        <TextInput
          label="Viajero"
          value={item?.funcionariosNombreViajero}
          classNames={{
            label: classes.labelSecondary,
            input: classes.input,
          }}
          disabled={true}
        />
      </Grid.Col>
      <Grid.Col span={6}>
        <TextInput
          label="Tipo de viaje"
          value={findTravelName(item?.paramTipoViaje)}
          classNames={{
            label: classes.labelSecondary,
            input: classes.input,
          }}
          disabled={true}
        />
      </Grid.Col>
      <Grid.Col span={6}>
        <TextInput
          label="Fecha inicio"
          value={utils.parseDateLocaleFormat(item?.fechaInicio)}
          classNames={{
            label: classes.labelSecondary,
            input: classes.input,
          }}
          disabled={true}
        />
      </Grid.Col>
      <Grid.Col span={6}>
        <TextInput
          label="Fecha Fin"
          value={utils.parseDateLocaleFormat(item?.fechaFin)}
          classNames={{
            label: classes.labelSecondary,
            input: classes.input,
          }}
          disabled={true}
        />
      </Grid.Col>

      {(!item?.multiviaje ? [item?.listaDestinos[0]] : item?.listaDestinos).map(
        (city, cityIndex) => (
          <React.Fragment key={cityIndex}>
            {renderCity(city?.nombreCiudadOrigen, "Ciudad Origen")}
            {renderCity(city?.nombreCiudadDestino, "Ciudad Destino")}
          </React.Fragment>
        )
      )}

      <Grid.Col span={6}>
        <TextInput
          label="Fecha máxima de legalización"
          value={utils.parseDateLocaleFormat(item?.fechaMaximaLegalizacion)}
          classNames={{
            label: classes.labelSecondary,
            input: classes.input,
          }}
          disabled={true}
        />
      </Grid.Col>
      <Grid.Col span={6}>
        <TextInput
          label="Total del viaje"
          value={utils.formatter.format(
            item?.anticipoViaje?.[0]?.totalConServiciosCop
          )}
          classNames={{
            label: classes.labelSecondary,
            input: classes.input,
          }}
          disabled={true}
        />
      </Grid.Col>

      <Grid.Col span={12}>
        <Flex align="center" gap={8} justify="flex-start" wrap="wrap">
          {renderButtons(item)}
        </Flex>
      </Grid.Col>
    </Grid>
  );
};

export default TravelCard;
