import { Grid, Select, TextInput, Flex, Button } from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { LuChevronDown } from "react-icons/lu";

const FormFilter = ({
  GlobalCard,
  form,
  handleSubmit,
  historicalList,
  classes,
  utils,
  data,
  isAux,
  hasSearched,
  resetFilter,
}) => {
  return (
    <GlobalCard>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Grid columns={10}>
          <Grid.Col xs={10} md={2}>
            <Select
              {...form.getInputProps("status")}
              label="Estado"
              placeholder="Seleccione un estado"
              rightSection={<LuChevronDown size="1rem" color="#004236" />}
              data={historicalList.map((item) => ({
                value: item.id.join("/"),
                label: item.description,
              }))}
              classNames={{
                label: classes.labelSecondary,
                input: classes.input,
                item: classes.item,
                rightSection: classes.rightSection,
              }}
            />
          </Grid.Col>
          <Grid.Col xs={10} md={2}>
            <DatePickerInput
              {...form.getInputProps("dates")}
              rightSection={
                form.values.dates.includes(null) ? (
                  <LuChevronDown size="1rem" color="#004236" />
                ) : null
              }
              label="Rango de fechas"
              locale={utils.es}
              placeholder="Seleccione fechas"
              clearable
              classNames={{
                label: classes.labelSecondary,
                input: classes.input,
                day: classes.calendar,
                rightSection: form.values.dates.includes(null)
                  ? classes.rightSection
                  : "",
              }}
              type="range"
              valueFormat="DD-MMM-YY"
            />
          </Grid.Col>
          <Grid.Col xs={10} md={2}>
            <Select
              {...form.getInputProps("destinationCity")}
              data={data?.allCities}
              label="Ciudad de destino"
              searchable
              nothingFound="No hay resultados"
              rightSection={<LuChevronDown size="1rem" color="#004236" />}
              placeholder="Ingrese ciudad"
              classNames={{
                dropdown: classes.dropdown,
                rightSection: classes.rightSection,
                label: classes.labelSecondary,
                input: classes.input,
                item: classes.item,
              }}
            />
          </Grid.Col>
          <Grid.Col xs={10} md={2}>
            <TextInput
              {...form.getInputProps("idUser")}
              label={`Registro del ${isAux ? "titular" : "viajero"}`}
              placeholder={`Ingrese el registro del ${
                isAux ? "titular" : "viajero"
              }`}
              classNames={{
                label: classes.labelSecondary,
                input: classes.input,
              }}
              required
            />
          </Grid.Col>
          <Grid.Col xs={10} md={2}>
            <TextInput
              {...form.getInputProps("idAid")}
              label={`Número del ${isAux ? "auxilio" : "viaje"}`}
              placeholder={`Ingrese el número del ${
                isAux ? "auxilio" : "viaje"
              }`}
              classNames={{
                label: classes.labelSecondary,
                input: classes.input,
              }}
            />
          </Grid.Col>
          <Grid.Col xs={10} md={4} offsetMd={6}>
            <Flex align="center" gap={16}>
              {hasSearched && (
                <Button
                  className={classes.buttonOutline}
                  fullWidth
                  type="button"
                  onClick={resetFilter}
                >
                  Limpiar filtros
                </Button>
              )}
              <Button className={classes.button} fullWidth type="submit">
                Buscar
              </Button>
            </Flex>
          </Grid.Col>
        </Grid>
      </form>
    </GlobalCard>
  );
};

export default FormFilter;
